import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthService } from "./auth.service";
import { GrantService } from "./grant.service";
import { menuDefinition } from "./master/menu";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    protected readonly router: Router,
    protected readonly auth: AuthService,
    protected readonly grant: GrantService
  ) {
    this.router.events.subscribe((params: any) => {
      this.handleMigratedPages(params.url);
    });
  }

  protected async handleMigratedPages(url: string) {
    const isMigrated = !!menuDefinition?.find(
      (m) => m.children.find((c) => c.route === url)?.isMigrated === true
    );

    if (isMigrated) {
      await this.router.navigate(["/migrate"]);
    }

    return isMigrated;
  }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    await this.auth.refreshToken(true);

    const returnUrl = state.url !== "/" ? state.url : null;
    const hasAccess = this.grant.isRouteAccessable(state.url);

    if (await this.handleMigratedPages(state.url)) {
      return false;
    }

    if (!hasAccess) {
      return await this.router.navigate(["/login"], {
        queryParams: { returnUrl: returnUrl },
      });
    } else if (!this.auth.user) {
      await this.auth.fetchUserDetails();
    }

    return true;
  }
}
